// Utilities
import { defineStore } from "pinia"
const PROJECT_LIST_LOCAL_STORAGE_KEY = "admin-preference"

export const usePreferenceStore = defineStore("admin-preference", {
	state: () => ({
		adminPreferences:
			JSON.parse(localStorage.getItem(PROJECT_LIST_LOCAL_STORAGE_KEY)) || {},
	}),
	getters: {
		getPreference() {
			if (this.adminPreferences == undefined) return null
			return this.adminPreferences
		},
	},
	actions: {
		setPreference(key, value) {
			this.adminPreferences[key] = value
			localStorage.setItem(
				PROJECT_LIST_LOCAL_STORAGE_KEY,
				JSON.stringify(this.adminPreferences),
			)
		},
	},
})
